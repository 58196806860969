<template>
<div>{{result}}</div>
</template>

<script>
import {storeList} from "@/api/yaojian";

export default {
  name: "test",
  data() {
    return {
      result: []
    }
  },
  created() {
    this.test()
  },
  methods: {
    async test() {
      let rs=await storeList();
      this.result=rs;
      console.log(rs);
    }
  }
}
</script>

<style scoped>

</style>
